import React from 'react';
import { PagePlaceholder, PageLoaderStyled, LoaderStyled } from './style';

export const PageLoader = () => {
    return (
        <React.Fragment>
            <PagePlaceholder/>
            <PageLoaderStyled>
                <LoaderStyled>
                    <div className="coll">
                        <div className="rowl v1 d1"></div>
                        <div className="rowl v3 d2"></div>
                        <div className="rowl v3 d3"></div>
                    </div>
                    <div className="coll">
                        <div className="rowl v3 d6"></div>
                        <div className="rowl v3 d5"></div>
                        <div className="rowl v3 d4"></div>
                    </div>
                    <div className="coll">
                        <div className="rowl v3 d7"></div>
                        <div className="rowl v3 d8"></div>
                        <div className="rowl v2 d9"></div>
                    </div>
                </LoaderStyled>
            </PageLoaderStyled>
        </React.Fragment>
    )
};

export const Loader = () => {
    return (
        <PagePlaceholder>
            <LoaderStyled>
                <div className="coll">
                    <div className="rowl v1 d1"></div>
                    <div className="rowl v3 d2"></div>
                    <div className="rowl v3 d3"></div>
                </div>
                <div className="coll">
                    <div className="rowl v3 d6"></div>
                    <div className="rowl v3 d5"></div>
                    <div className="rowl v3 d4"></div>
                </div>
                <div className="coll">
                    <div className="rowl v3 d7"></div>
                    <div className="rowl v3 d8"></div>
                    <div className="rowl v2 d9"></div>
                </div>
            </LoaderStyled>
        </PagePlaceholder>
    )
}
import styled from 'styled-components';
import {  MOBILE_MEDIA_BREAK } from 'components/Common/Layout';
import Background from 'assets/images/hero/bg_footer.png';

export const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: url(${Background});
    background-position-x: calc(100% + 0px);
    /* background-position-y: calc(100% + 110px) !important; */
    background-position-y: 0;
    /* height: 1100px; */
    background-size: cover;
    background-repeat: no-repeat;
    padding: 110px 110px 0 110px;
    @media (max-width: 1300px) {
        background-position-x: calc(100% + 100px);
    }
    @media (max-width: 1215px) {
        background-position-x: calc(100% + 2px);
        /* background-position-y: calc(100% + 166px); */
        padding: 50px 50px 0 50px;
        /* height: 900px; */
    }
    @media (max-width: 1200px) {
        background-position-x: calc(100% + 166px);
        /* background-position-y: calc(100% + 12px); */
    }
    @media (max-width: 1024px) {
        /* background-position-y: calc(100% + 2px); */
        background-position-x: calc(100% + 222px);
    }
    @media (max-width: 892px) {
        /* background-position-y: calc(100% + 2px); */
        background-position-x: calc(100% + 265px);
    }
    @media (max-width: 768px) {
        /* background-position-y: calc(100% + 2px); */
        background-position-x: calc(100% + 464px);
        padding: 4% 4% 0 4%;
        /* height: 1170px; */
    }
    @media (max-width: 550px) {
        /* background-position-y: calc(100% + 10px); */
        background-position-x: calc(100% + 464px);
       /*  height: 1220px; */
    }
    @media (max-width: 410px) {
        /* background-position-y: calc(100% + 146px); */
        background-position-x: calc(100% + 464px);
        /* height: 1400px; */
    }
`;

export const GetStartedCard = styled.div`
    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
    height: 380px;
    padding: 4%;
    outline: none;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e5e5e5 !important;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    transition: all 0.5s;
    h2 {
        color: var(--primary);
        font-weight: 800;
        padding: 30px 0px 15px;
        font-size: 1.75em;
        line-height: 1.125;
    }
    h2.title-bold-blue {
        color: var(--primary);
        font-weight: 800;
        padding: 30px 0px 15px 0px;
    }
    p:not(:last-child) {
        margin-bottom: 0 !important;
    }
    a.button-blue {
        color: #FFFFFF;
        transition: all 0.5s;
        font-weight: 600;
        border-color: var(--primary_variant);
        background-color: var(--primary_variant);
        border-radius: 0px;
        padding: 22px 40px !important;
        &:hover {
            opacity: 0.6;
        }
    }
    .icon-bar {
        width: 100%;
        padding: 10px;
        overflow: auto;
        a {
            text-align: center;
            transition: all 0.3s ease;
            color: var(--primary_variant);
            cursor: pointer;
            margin: 0px 3px 0px 3px;
            padding: 0 5px;
            text-decoration: none;
            &:hover {
                color: var(--primary);
            }
        }
    }
`;

export const FooterContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat( auto-fit, minmax(240px, 1fr));
    gap: 1px 1px;
    grid-template-areas: "footer-column-01 footer-column-02 footer-column-03 footer-column-04"
        "footer-column-01 footer-column-02 footer-column-03 footer-column-04" 
        "footer-copyright footer-copyright footer-copyright footer-copyright";
    }
    .footer-column-01 { grid-area: footer-column-01; }
    .footer-column-02 { grid-area: footer-column-02; }
    .footer-column-03 { grid-area: footer-column-03; }
    .footer-column-04 { grid-area: footer-column-04; }
    .footer-copyright { grid-area: footer-copyright; }
    
    h4.title {
        color: white !important;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
    }
    ul {
        list-style-type: none;
        .button.btn-align {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            padding: 0px 0px !important;
            color: white;
            background: transparent;
            border-color: transparent;
            &.is-drop {
                position: relative;
                cursor: pointer;
                :hover {
                    color: var(--primary_variant);
                }
            }
        }
        li {
            padding: 0px 0px;
            color: lightgray;
            font-size: 18px;
            font-family: 'Poppins', sans-serif;
            a {
                color: white;
                font-size: 16px;
                font-weight: 200;
            }
        }
    }

    .is-drop {
        position: relative;
        cursor: pointer;
        .dropContain { 
            width: 220px;
            position: absolute;
            z-index: 3;
            left: 50%;
            margin-left: -9999px;
            // top: -500vh;
            .dropOut {
                width: 220px;
                background: #fff;
                float: left;
                position: relative;
                margin-top: 15px;
                opacity: 0;
                border: 1px solid #ededed;
                border-radius: 4px;
                box-shadow: 0 3px 10px 4px rgba(0,0,0,0.04);
                transition: all .5s ease-out;
                ul {
                    float: left;
                    padding: 10px 0;
                    list-style-type: none;
                    li {
                        text-align: left;
                        float: left;
                        width: 200px;
                        padding: 12px 0 10px 15px;
                        margin: 3px 10px;
                        color: #777;
                        border-radius: 4px;
                        transition: background .2s ease-out
                        &:hover {
                            background: #EFF4F7;
                            cursor: pointer
                        }
                    }
                }
            }
        }
        &:hover {
            .dropContain {
                top: 30px !important;
                margin-left: -35px !important;
                .dropOut {
                    -webkit-animation: fadeInUp 0.27s ease-out;
                    animation: fadeInUp 0.27s ease-out;
                    opacity: 1;
                }
            }
        }
    }

    @media (max-width: ${MOBILE_MEDIA_BREAK}px) {
        align-items: flex-end;
        .align-self-start {
            align-self: flex-start;
        }
        .align-self-center {
            align-self: center;
        }
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "footer-column-01 footer-column-01 footer-column-02 footer-column-02" 
            "footer-column-03 footer-column-03 footer-column-04 footer-column-04" 
            "footer-copyright footer-copyright footer-copyright footer-copyright";
        }
        .footer-column-01 { grid-area: footer-column-01; }
        .footer-column-02 { grid-area: footer-column-02; }
        .footer-column-03 { grid-area: footer-column-03; }
        .footer-column-04 { grid-area: footer-column-04; }
        .footer-copyright { grid-area: footer-copyright; }
        [class*='footer-column-'] {
            padding: 4%;
            align-self: baseline;
        }
    }
`;

export const Copyright = styled.div`
    margin-top: 4%;
    p {
        padding: 0px 0px;
        color: white;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 30px;
    }
`;
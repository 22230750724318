import React from 'react';
import { Link } from 'react-router-dom';
import { StyledFooter, GetStartedCard, FooterContent, Copyright } from './style';

export const PlainFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
        <StyledFooter>
            <div className="columns is-vcentered">
                <div className="column">    
                </div>
                <div className="column is-7">  
                    <GetStartedCard>
                        {/* <h2 className="no-margin title-bold-blue">Start Your 15-day trial</h2>
                        <p className="pt-10 pb-20">No credit card necessary</p>
                        <Link to={`${process.env.PUBLIC_URL}/register`} className="button button-blue">
                            Get Started
                        </Link>
                        <p className="pt-40">Become part of our ever growing community.</p>
                        <p>Join us on our socials!</p>
                        <div className="icon-bar">
                            <Link to="https://www.facebook.com/Socrates-Developers-405860556897727">
                                <span className="ti-facebook"></span>
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                <span className="ti-twitter-alt"></span>
                            </Link>
                            <Link to="https://www.instagram.com/socrates.buzz/">
                                <span className="ti-instagram"></span>
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                <span className="ti-pinterest-alt"></span>
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                <span className="ti-linkedin"></span>
                            </Link>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                <span className="ti-youtube"></span>
                            </Link>
                        </div> */}
                    </GetStartedCard> 
                </div>
                <div className="column">  
                </div>
            </div>
            <div className="pb-100"/>
            <FooterContent>
                {/* <div className="footer-column-01 align-self-start">
                    <h4 className="title is-light is-5 margin-bottom">Language</h4>
                    <ul className="footer-menu">
                        <div className="button btn-align has-icon-right is-drop is-right">
                            English&nbsp;
                            <i className='fas fa-angle-down'/>
                            <div className="dropContain">
                                <div className="dropOut">
                                    <ul>
                                        <li>Dutch</li>
                                        <li>Spanish</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div> */}

                <div className="footer-column-02">
                    <h4 className="title is-light is-5">About</h4>
                    <ul className="footer-menu">
                        {/* <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Support Center
                            </Link>
                        </li> */}
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Customer Support
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Copyright
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Popular Campaign
                            </Link>
                        </li> */}
                    </ul>
                </div>

                <div className="footer-column-03 align-self-center">
                    <h4 className="title is-light is-5">Our Information</h4>
                    <ul className="footer-menu">
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/legal`}>
                                Legal
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/faq`}>
                                FAQ
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/terms-and-condition`}>
                                Terms & Conditions
                            </Link>
                        </li> */}
                        {/* <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Site Map
                            </Link>  
                        </li>            */}
                    </ul>
                </div>

                <div className="footer-column-04">
                    <h4 className="title is-light is-5">My Account</h4>
                    <ul className="footer-menu">
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/login`}>
                                Login
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Press Inquiries
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Social Media Directories
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Images & Videos
                            </Link>
                        </li>
                        <li>
                            <Link to={`${process.env.PUBLIC_URL}/#`}>
                                Permissions
                            </Link>
                        </li> */}
                    </ul>
                </div>
                <Copyright className="footer-copyright">
                    <p>Copyright { currentYear } @Socrates.</p>
                </Copyright>
            </FooterContent>
        </StyledFooter>
    )
}
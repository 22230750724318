import styled from 'styled-components';

export const PagePlaceholder = styled.div`
    position: relative;
    height: 700px;
    background-color: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageLoaderStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 40, 80, 0.7) !important;
    z-index: 9999;
`; 

export const LoaderStyled = styled.div`

    & > .coll {
        height: 15px;
    }
    & > .coll > .rowl {
        width: 10px;
        height: 10px;
        margin: 0 3px;
        display: inline-block;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }
    & > .coll > .rowl.v1 {
        animation-name: lf1;
    }
    & > .coll > .rowl.v2 {
        animation-name: lf2;
    }
    & > .coll > .rowl.v3 {
        animation-name: lf3;
    }
    .d1 {
        animation-delay: 0.111111s;
    }
    .d2 {
        animation-delay: 0.222222s;
    }
    .d3 {
        animation-delay: 0.333333s;
    }
    .d4 {
        animation-delay: 0.444444s;
    }
    .d5 {
        animation-delay: 0.555555s;
    }
    .d6 {
        animation-delay: 0.666666s;
    }
    .d7 {
        animation-delay: 0.777777s;
    }
    .d8 {
        animation-delay: 0.888888s;
    }
    .d9 {
        animation-delay: 0.999999s;
    }

    @keyframes lf1 {
        0% {
        background-color: #38c0ed;
        left: 0px;
        top: 0px;
        }
        25% {
        background-color: #f1f3f4;
        left: 200px;
        top: 0px;
        }
        100% {
        background-color: #38c0ed;
        left: 0px;
        top: 0px;
        }
    }
    @keyframes lf2 {
        0% {
        background-color: #f1f3f4;
        left: 0px;
        top: 0px;
        }
        25% {
        background-color: #38c0ed;
        left: 200px;
        top: 0px;
        }
        100% {
        background-color: #f1f3f4;
        left: 0px;
        top: 0px;
        }
    }
    @keyframes lf3 {
        0% {
        background-color: transparent;
        left: 0px;
        top: 0px;
        }
        25% {
        background-color: #f1f3f4;
        left: 200px;
        top: 0px;
        }
        70% {
        background-color: transparent;
        left: 0px;
        top: 0px;
        }
    }
`;
import styled from 'styled-components';
import {  MOBILE_S_MEDIA_BREAK, TABLET_MEDIA_BREAK } from 'components/Common/Layout';

export const StyledHeader = styled.header`
    position: sticky !important;
    background-color: var(--primary);
    .container {
        max-width: 1345px;
    }
    .navbar-brand {
        align-items: center;
        img {
            width: 280px;
            height: 70px;
        }
    }
    .navbar-menu {
        background-color: var(--primary);
        margin: 0 !important;
        text-align: center;
        .navbar-item {
            color: white;
            font-weight: 600;
            margin-right: 10px;
            margin-left: 10px;
            transition: all .3s;
            &:hover {
                color: var(--primary_variant);
            }
            .button {
                color: white;
                width: 220px;
                background: var(--primary_variant);
                border-color: var(--primary_variant);
                &:hover {
                    background: white;
                    color: var(--primary_variant);
                    border-width: 2px;
                }
                @media (max-width: ${TABLET_MEDIA_BREAK}px) {
                    width: 95%;
                }
                @media (max-width: ${MOBILE_S_MEDIA_BREAK}px) {
                    width: 80%;
                }
            }
        }
    }
`;

export const NavbarBurger = styled.a`
    color: white;
    &:hover {
        color: white !important;
    }
`;

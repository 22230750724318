import React from 'react';
import { Link } from 'react-router-dom';
import { StyledHeader, NavbarBurger } from './style';
import Logo from 'assets/images/logo/logo-socrates-white.svg';

function toggleNavBar(e) {
    const target = document.querySelector('.navbar-menu');
    if (target) {
        target.classList.toggle('is-active');
    }
}

function closeNavbarWhenActive() {
    const target = document.querySelector('.navbar-menu');
    if (target.classList.contains('is-active')) {
        target.classList.remove('is-active')
    }
}

export const Header = (props) =>
    <StyledHeader className='navbar is-transparent is-fixed-top is-faded'>
        <div className='container'>
            <div className='navbar-brand'>
                <Link to={`${process.env.PUBLIC_URL}/`}>
                    <img src={Logo} alt='Socrates Logo' />
                </Link>
                <NavbarBurger
                    // href={`${process.env.PUBLIC_URL}/#`} 
                    role="button"
                    className="navbar-burger burger"
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbar-menu"
                    onClick={toggleNavBar}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </NavbarBurger>
            </div>
            <div className="navbar-menu">
                <div className="navbar-end has-hover">
                    <Link to={`${process.env.PUBLIC_URL}/about`} onClick={() => closeNavbarWhenActive()} className='navbar-item has-border-right'>
                        About
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/features`} onClick={() => closeNavbarWhenActive()} className='navbar-item has-border-right'>
                        Features
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/contact-us`} onClick={() => closeNavbarWhenActive()} className='navbar-item has-border-right'>
                        Pricing
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/contact-us`} onClick={() => closeNavbarWhenActive()} className='navbar-item has-border-right'>
                        Contact Us
                    </Link>
                    <Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => closeNavbarWhenActive()} className='navbar-item has-border-right'>
                        Login
                    </Link>
                    <div className="navbar-item">
                        <a href={`/register`} className='button cta-button accent-button'>
                            Sign up
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </StyledHeader>;
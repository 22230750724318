import styled from 'styled-components';
import {  TABLET_MEDIA_BREAK } from 'components/Common/Layout';

export const StyledGuide = styled.section`
    .text-centered {
        padding-bottom: 20px;
        h2 {
            font-family: 'Poppins', sans-serif;
            font-size: 36px;
            font-weight: 700;
            color: #0f2850;
        }
        p {
            font-family: 'Nexa Light', sans-serif;
            font-weight: 500;
            padding: 10px 20px;
            font-size: 18px;
            color: var(--primary);
        }
    }

    .column {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 65px;

        @media (max-width: ${TABLET_MEDIA_BREAK}px) {
            padding: 4%;
        }
    }
`;

export const GuideIcon = styled.img`
    width: 300px;
    height: 75px;
    margin: 10px 0;
`;

export const GuideDescription = styled.p`
    font-size: 95%;
    color: var(--primary);
    font-family: 'Poppins', sans-serif;
    margin-left: 10px;
`;
import React from 'react';
import Fade from 'react-reveal/Fade';
import { StyledGuide, GuideIcon, GuideDescription} from './style';
import SupportIcon from 'assets/images/icons/ico-support.svg';
import HelpIcon from 'assets/images/icons/ico-help.svg';
import CallIcon from 'assets/images/icons/ico-call.svg';

export const Guide = () => {
    return (
        <StyledGuide>
            <div className="container">
                <div className="text-centered">
                    <h2 className=" centered-title-style">Socrates is where it's at!</h2>
                    <h3>We'll guide you along the way</h3>
                </div>
                <div className="columns is-vcentered">
                    <div className="column is-4">
                        <Fade bottom>
                            <GuideIcon className="guide-icon" src={SupportIcon} alt=""/>
                        </Fade>
                        <GuideDescription>
                            Do you need any assistance or tutorials regarding any of our services? We can provide in-depth one-on-one demos!
                        </GuideDescription>
                    </div>
                    <div className="column is-4">
                        <Fade bottom delay={200}>
                            <GuideIcon className="guide-icon" src={HelpIcon} alt=""/>
                        </Fade>
                        <GuideDescription>
                            Looking for some Socrates tips and tricks? Browse our FAQ section for more information about our techniques and other specifics.
                        </GuideDescription>
                    </div>
                    <div className="column is-4">
                        <Fade bottom delay={400}>
                            <GuideIcon className="guide-icon" src={CallIcon} alt=""/>
                        </Fade>
                        <GuideDescription>
                            Need help with something not answered in our FAQ? Reach out to us, we're more than happy to answer your queries.
                        </GuideDescription>
                    </div>
                </div>
            </div>
        </StyledGuide>
    )
}
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import 'styles/styles.scss';
import { PageLoader } from 'components/Common/Loader';
import { Header } from 'components/Header';
import { Guide } from 'components/Guide';
import { Footer } from 'components/Footer';
import { PlainFooter } from 'components/Footer/Plain';

const Home = lazy(() => import('components/Home'));
const About = lazy(() => import('components/About'));
const Features = lazy(() => import('components/Features'));
const Pricing = lazy(() => import('components/Pricing'));
const ContactUs = lazy(() => import('components/ContactUs'));
const Login = lazy(() => import('components/Login'));
const Register = lazy(() => import('components/Register'));
const TestStripe = lazy(() => import('components/TestStripe'));
const PrivacyPolicy = lazy(() => import('components/Legal/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('components/Legal/TermsAndConditions'));
const Legal = lazy(() => import('components/Legal'));
const Faq = lazy(() => import('components/Faq'));
const FaqIssues = lazy(() => import('components/Faq/Issues'));
const FaqFbAdmin = lazy(() => import('components/Faq/Issues/NotFbAdmin'));
const FaqPermission = lazy(() => import('components/Faq/Issues/Permission'));
const Faq200Error = lazy(() => import('components/Faq/Issues/200Error'));
const RefundPolicy = lazy(() => import('components/Legal/RefundPolicy'));
const OrderPolicy = lazy(() => import('components/Legal/OrderPolicy'));
const CookiePolicy = lazy(() => import('components/Legal/CookiePolicy'));
const CookieTable = lazy(() => import('components/Legal/CookieTable'));
const SubProcessor = lazy(() => import('components/Legal/SubProcessor'));

const App = () => {
  return (
    <div>
      <Router>
        <PageWrapperRouter>
          <ScrollToTopRouter>
            <Suspense fallback={<PageLoader/>}>
              <Switch>
                  <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                  <Route path={`${process.env.PUBLIC_URL}/about`} component={About} />
                  <Route path={`${process.env.PUBLIC_URL}/features`} component={Features} />
                  <Route path={`${process.env.PUBLIC_URL}/pricing`} component={Pricing} />
                  <Route path={`${process.env.PUBLIC_URL}/contact-us`} component={ContactUs} />
                  <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                  <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                  <Route path={`${process.env.PUBLIC_URL}/test-stripe`} component={TestStripe} />
                  <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} />
                  <Route path={`${process.env.PUBLIC_URL}/refund-policy`} component={RefundPolicy} />
                  <Route path={`${process.env.PUBLIC_URL}/order-policy`} component={OrderPolicy} />
                  <Route path={`${process.env.PUBLIC_URL}/terms-and-condition`} component={TermsAndConditions} />
                  <Route path={`${process.env.PUBLIC_URL}/legal`} component={Legal} />
                  <Route path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
                  <Route path={`${process.env.PUBLIC_URL}/faq-issues`} component={FaqIssues} />
                  <Route path={`${process.env.PUBLIC_URL}/faq-issues-fb-admin`} component={FaqFbAdmin} />
                  <Route path={`${process.env.PUBLIC_URL}/faq-issues-permission-error`} component={FaqPermission} />
                  <Route path={`${process.env.PUBLIC_URL}/faq-issues-200-error`} component={Faq200Error} />
                  <Route path={`${process.env.PUBLIC_URL}/cookie-policy`} component={CookiePolicy} />
                  <Route path={`${process.env.PUBLIC_URL}/cookie-table`} component={CookieTable} />
                  <Route path={`${process.env.PUBLIC_URL}/sub-processors`} component={SubProcessor} />
              </Switch> 
            </Suspense>
          </ScrollToTopRouter>
        </PageWrapperRouter>
      </Router>
    </div>
  );
}

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // window.scrollTo(0, 0)
      document.querySelector('html').scrollTop = 0;
    }
  }

  render() {
    return this.props.children
  }
}

const PageWrapper = (props) => {
  const validationRoutes = ['login', 'register'];
  const legalRoutes = [
    'privacy-policy', 
    'terms-and-condition', 
    'legal', 
    'refund-policy', 
    'order-policy', 
    'cookie-policy', 
    'cookie-table',
    'sub-processors'
  ];
  const { location } = props;
  const arrLocation = location.pathname.split('/');
  const currentPath = arrLocation[arrLocation.length - 1];
  if (validationRoutes.includes(currentPath)) {
    return (
      <React.Fragment>
        <Header/>
        { props.children }
      </React.Fragment>
    )
  }
  else if(legalRoutes.includes(currentPath)){
    return (
      <React.Fragment>
        <Header/>
        { props.children }
        <PlainFooter/>
      </React.Fragment>
    )
  }
  else {
    return (
      <React.Fragment>
        <Header/>
        { props.children }
        <Guide/>
        <Footer/>
      </React.Fragment>
    )
  }
}

const ScrollToTopRouter = withRouter(ScrollToTop);
const PageWrapperRouter = withRouter(PageWrapper);

export default App;
